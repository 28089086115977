import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const WarensortimentPage = () => (
  <Layout>
    <SEO title="Warensortiment" />

    <h1  id="ueberschrift">Informationen zum Warenangebot</h1>
    <Link to="/fdml_2a_warensortiment-klartext#ueberschrift" title="Detaillierte Informationen zum Warenangebot" style={{fontSize: "1.5em"}}>zum Klartext</Link>

    <p className="poem" style={{marginTop: "1.5em"}}>
          Bleibt nie lang an einem Ort,<br/>
          Drehst Du Dich um, ist er schon fort.<br/>
          Doch wählst Du meine Nummer an,<br/>
          Ich ihn für Dich fangen kann.
    </p>

    <p className="poem" style={{marginTop: "0.5em"}}>
      Dort gibt es Gargoyles, Feen und Drachen<br />
      Und auch noch ganz normale Sachen<br />
      Wie Wölfe, Elefanten, Tiger<br />
      Zum Basteln: Perlen, Gleise, Flieger<br />
      Manchmal müsst Ihr etwas warten<br />
      Doch nicht auf Würfel oder Karten<br />
      Auch nicht auf Spielzeug für die Kleinen<br />
      Denn sie sollen ja nicht weinen...
    </p>

  </Layout>
)

export default WarensortimentPage
